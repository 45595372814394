import React from "react"
import { Seo } from "src/component/common/seo"
import Industries from "src/component/layout/industries"
import { StaticImage } from "gatsby-plugin-image"
import ConstructionCasestudyList from "src/component/list/constructionCasestudyList"

export default function Construction() {
    return (
        <>
         <Industries>
          <div className="construnction inner-content">
            <section className="introduction__mv">
                    <div className="introduction__mv__inner c-inner-primary">
                    <div className="introduction__mv__img">
                            <StaticImage
                              src="../../assets/img/pages/industries/industries_construction.png"
                              alt="株式会社DATAFLUCT（データフラクト）"
                            placeholder="blurred"
                            className="pc-show"
                            />
                            <StaticImage
                              src="../../assets/img/pages/industries/industries_construction-sp.png"
                              alt="株式会社DATAFLUCT（データフラクト）"
                            placeholder="blurred"
                            className="sp-show"
                            />
                    </div>

                        <div className="introduction__mv__content">
                                <h2 className="introduction__mv__content__title">
                                 <p>建設/不動産 </p>
                                  <span>CONSTRUCTION/REAL ESTATE</span>
                                </h2>
                                <p className="introduction__mv__lead">
                                  膨大なデータを抱えながらも活用が難しい建設・不動産業界の課題を解決。
                                  <br />
                                  図面からセンサーまで多様なデータを構造化し、業務効率化と高品質化を実現します。
                               </p>
                         </div>
        
                    </div>
                </section>
                



                <section className="introduction__content">
                    <div className="introduction__content__inner c-inner-primary">
                        <div className="introduction__content__title">
                            <h2 className="js-scroll">
                                <div><div><span>AIによる効率化と<br />データ活用で、都市開発を<br className="c-sp" />次のステージへ</span></div></div>
                            </h2>
                        </div>
                        <div className="introduction__content__textwrap">
                            <ul>
                                <li className="introduction__content__text">
                                 <h3>建設データ基盤の構築</h3>
                                 <p>
                                  建設データ基盤の構築
                                  <br />
                                  BIMデータや施工データを統合し、プロジェクトの可視化を実現。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>エネルギー効率の最適化</h3>
                                 <p>
                                    AIが建物のエネルギー消費を分析し、最適な省エネ対策を提案。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>AIによる建設現場の進捗管理</h3>
                                 <p>
                                  AIによる建設現場の進捗管理
                                  <br />
                                  AIが施工データを解析し、工事の進捗やリスクを予測。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>スマートシティへの発展</h3>
                                 <p>
                                    都市のデータを統合し、持続可能な都市開発を支援。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>不動産価格の予測と評価</h3>
                                 <p>
                                  不動産価格の予測と評価
                                  <br />
                                  AIが市場動向を分析し、適正な不動産価格を算出。
                                 </p>
                                </li>

                            </ul>
         
                        </div>
                    </div>
                </section>

                <section className="p-top-casestudy">
                    <div className="p-top-casestudy__inner c-inner-primary">
                        <div className="p-top-casestudy__title c-title01">
                            <h2 className="p-top-casestudy__title__text c-title01__text">導入事例</h2>
                        </div>
                        <ConstructionCasestudyList />
                    </div>
                </section>
              </div>
           </Industries>
        </>
    )
}

export function Head() {
    return (
        <Seo
            title="CONSTRUCTION"
            description=""
            pathname=""
        />
    )
}