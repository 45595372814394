import React, { useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import Layout from "src/component/layout/layout"
import Tabs from "src/component/common/tabs"
import { Seo } from "src/component/common/seo"

export default function Industries({ children }) {
  const location = useLocation()

  // URLのパスセグメントを取得
  const pathSegments = location.pathname.split("/")
  const currentTab = pathSegments[pathSegments.length - 1] // URLの最後の部分を取得

  // 有効なタブのリスト
  const validTabs = ["food", "retail", "public", "construction", "manufacturing", "finance"]

  // URLから取得した値が有効なタブかチェック、無効な場合は "food" に設定
  const initialTab = validTabs.includes(currentTab) ? currentTab : "food"

  const [activeTab, setActiveTab] = useState(initialTab)
  const [fade, setFade] = useState(true)

  // location.pathname が変わった時に activeTab を更新
  useEffect(() => {
    const newPathSegments = location.pathname.split("/")
    const newCurrentTab = newPathSegments[newPathSegments.length - 1]
    const newInitialTab = validTabs.includes(newCurrentTab) ? newCurrentTab : "food"
    
    console.log("Updated Tab:", newInitialTab) // 追加: 現在のタブをコンソールに表示
    setActiveTab(newInitialTab) // activeTab を更新
  }, [location.pathname]) // location.pathname が変わるたびに実行

  // スクロールアニメーションの処理
  useEffect(() => {
    let js_scroll = document.getElementsByClassName("js-scroll")
    const scroll = () => {
      for (let i = 0; i < js_scroll.length; i++) {
        if (
          window.pageYOffset + window.innerHeight / 1.5 >
          window.pageYOffset + js_scroll[i].getBoundingClientRect().top
        ) {
          js_scroll[i].classList.add("is-active")
        }
      }
    }
    scroll()
    window.addEventListener("scroll", () => {
      scroll()
    })
  }, [])

  return (
    <>
      <Layout>
        <div className="introduction">
          <div className="introduction__inner c-inner-primary">
            <h1 className="introduction__title title-bold">INDUSTRIES</h1>
            <div className="introduction__item__catchcopy title-bold-catchcopy c-pc js-scroll">
              <div>
                <div>
                  <span>業界別</span>
                </div>
              </div>
            </div>
            <div className="introduction__item__catchcopy title-bold-catchcopy c-sp js-scroll">
              <div>
                <div>
                  <span>業界別</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* タブコンポーネント */}
        <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />

        {/* 子コンテンツ */}
        {children}

        {/* 再度タブコンポーネントを表示 */}
        <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
      </Layout>
    </>
  )
}

export function Head() {
  return (
    <Seo
      title="INDUSTRIES"
      description=""
      pathname=""
    />
  )
}
